import { getQuarter, getYear } from 'date-fns'
import { minBy } from 'lodash'
import { computed } from 'vue'
import { useCurrentCompanyQuery } from '@/api/useCompany'
import { useQueryYearlyEmissionsStatistics } from '@/api/useYearlyEmissionsStatistics'
import { SelectsConfig } from '@/modules/action/config/actionFormConfig'

export const useDates = () => {
  const { data: company } = useCurrentCompanyQuery()
  const { data: yearlyEmissionsStatistic } = useQueryYearlyEmissionsStatistics()

  const currentYear = computed(() => getYear(new Date()))
  const currentQuarter = computed(() => getQuarter(new Date()))

  const companyCreatedYear = computed(() => {
    if (!company.value) {
      return currentYear.value
    }
    return getYear(new Date(company.value.createdAt))
  })

  const netZeroYear = computed(() => {
    const netZeroYear = company.value?.netZeroYear
    if (!netZeroYear) {
      return SelectsConfig.MaxYear
    }
    return netZeroYear
  })

  const baseYear = computed(() => {
    return company.value?.baseYear || null
  })

  const firstStatisticYear = computed(() => {
    return minBy(yearlyEmissionsStatistic.value, 'year')?.year
  })

  const firstYear = computed(
    () => firstStatisticYear.value ?? companyCreatedYear.value
  )

  return {
    baseYear,
    currentYear,
    currentQuarter,
    firstYear,
    netZeroYear,
  }
}
